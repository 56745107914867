<template>
  <figure class="game-center-item" :class="className">
    <nuxt-link :id="game.title" :to="game?.link" class="cursor-pointer">
      <div class="game-center-item__box">
        <div class="game-center-item__box--image">
          <BaseImg :relative-src="game.image" class="game-list--top" :class="{ 'img-highlight': isHighlight}" lazy :alt="game.title" />
        </div>
        <div class="game-center-item__box--title">
          <div v-if="sumJackpot[game.jackpot] > 0" class="jackpot">
            <AnimateCountUp
              :number="sumJackpot[game.jackpot]"
              :show-coin="true"
            />
          </div>
        </div>
        <div class="game-center-item__box--des">
          {{ game.description }}
        </div>
      </div>
    </nuxt-link>
  </figure>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import AnimateCountUp from '~/components/common/animate-count-up.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

defineProps({
  game: {
    type: Object,
    default: () => ({})
  },
  className: {
    type: String,
    default: ''
  },
  isShowIcon: {
    type: Boolean,
    default: false
  },
  isHighlight: {
    type: Boolean,
    default: false
  }
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/game-center/game-center-item.scss"></style>
